<template>
  <div>
    <div v-if="qrCode"  v-loading="qrLoading">
      <img :src="qrCode" alt="QR Code">
    </div>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
// import templateConstants from "../../../constants/lg-en/templates";
import { mapGetters } from "vuex";
import QRCode from 'qrcode';
export default {
  name: "templates-formComponentsExecute-SingleLineTextExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "checkIsDisabled",
    "profilePage",
    "colorFields",
    "entityDataId",
    "entityId"
  ],
  mounted() {
    this.loading = true;
    //this.defaultMethod();
    this.generateQRCode();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
    }
    this.password = this.form[this.data.key] ? '*'.repeat(this.form[this.data.key].length) : '';
    this.loading = false;
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      return this.data.styles &&
        this.data.styles.label_color &&
        !this.fromRepeatable
        ? `font-weight: 400; width: ${this.data.width}px;`
        : "";
    },

    computedStyles() {
      const styles = {};
      styles.display = 'flex';
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    inputTextAlignment() {
      return this.textAlignment
        ? this.textAlignment
        : 'left'; // Set a default value if not provided
    },

    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      qrLoading:false,
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      //  isDefalutPos: true,
      loading: false,
      inputValue: "",
      maskedValue: "",
      inputValue2: "",
      hideLabel: false,
      password: '',
      textAlignment: 'left',
      inputValue3: "",
      flag: true,
      qrCode: null,
    };
  },
  directives: {
    maskLastFour: {
      inserted(el) {
        el.addEventListener("blur", event => {
          const input = event.target;
          const inputValue = input.value;
          let maskedValue;
          if (inputValue.length <= 4) {
            maskedValue = inputValue;
          } else {
            let lastFourDigits = inputValue.slice(-4);
            maskedValue = "*".repeat(inputValue.length - 4) + lastFourDigits;
          }
          input.value = maskedValue;

          // input.dispatchEvent(new Event("blur"));
        });
      }
    }
  },

  methods: {
    generateQRCode() {
      this.qrLoading=true;
      if (this.data.validations.qrType=='From Field' && this.data.validations.qr_data_field && this.form && this.form[this.data.validations.qr_data_field])
      {
              this.qrOutput(this.form[this.data.validations.qr_data_field])
      }
      else if(this.data.validations.qrType=='Record Id' &&this.form )
      {
        // Record Id
        if(this.entityDataId)
        {
          this.qrOutput(this.entityDataId);
        }
        else{
        this.qrOutput('');
        }
      }
      else if(this.data.validations.qrType=='URL' &&this.form )
      {
        // Record Id
        const data={
          referenceId: this.entityId,
          dataId: this.entityDataId
        };
        this.qrOutput(JSON.stringify(data));
      }
      else {
        this.qrCode = null
        setTimeout(() => {
        this.qrLoading=false;
        }, 2000);
      }
    },
    qrOutput(data)
    {
      QRCode.toDataURL(data, { errorCorrectionLevel: 'H' }, (err, url) => {
          if (err) throw err;
          this.qrCode = url;
        });
        setTimeout(() => {
        this.qrLoading=false;
        }, 2000);
    },

    defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key]
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      } else if (
        !this.form[this.data.key] &&
        this.data.default_mapped_field &&
        this.form[this.data.default_mapped_field]
      ) {
        this.$set(
          this.form,
          this.data.key,
          this.form[this.data.default_mapped_field]
        );
      }
      this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "SINGLE_LINE_TEXT") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },


    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    isValidURL(url) {
      let re = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    'data.styles.inputTextAlignments': {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.textAlignment = newVal;
      },
      immediate: true // This will trigger the handler immediately when the component is created
    },
    'form': {
      handler(updatedData) {
        this.$nextTick(() => {
          this.generateQRCode(updatedData);
        },1000);
      }, immediate: true,
      deep: true,
    }
  },

};
</script>

<style lang="scss" scoped>
.sle {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;

  &:hover {
    border-color: #409EFF;
    /* Change the border color on hover */
  }

  &:focus {
    border-color: #409EFF;
    /* Change the border color when focused */
  }

  &::placeholder {
    color: #ccc;
    /* Use your desired lighter gray color here */
  }
}

.disabled-field {
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  color: #C0C4CC;
  /* Set your preferred greyed-out color */
  pointer-events: painted;
  cursor: not-allowed;
}
</style>
